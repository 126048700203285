import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import AboutMe from "./pages/about-me";
import NotFound from "./pages/not-found";
import Contact from "./pages/contact";
import "./App.css";
import MyProjects from "./pages/my-projects";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/my-projects" element={<MyProjects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
