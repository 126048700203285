import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";

const MyMenu = () => {
  return (
    <AppBar>
      <Container>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          ></IconButton>
          <Button variant="text">
            <Link to="/">
              <Typography variant="h5">Jahedyn Finlayson</Typography>
            </Link>
          </Button>
          <Stack spacing={2} direction="row">
            <Link to="/my-projects">
              <Button variant="outlined">My Projects</Button>
            </Link>

            <Link to="/about-me">
              <Button variant="outlined">About Me</Button>
            </Link>

            <Link to="/contact">
              <Button variant="outlined">Contact</Button>
            </Link>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyMenu;
