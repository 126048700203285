import { Grid } from "@mui/material"
import { Typography } from "@mui/material"
import PageWrapper from "../../components/page-wrapper"

const AboutMe = () => {
  return (
    <PageWrapper>
      <>
        <Grid item xs={12} md={6}>
          <Typography>About Me and I</Typography>
        </Grid>
      </>
    </PageWrapper>
  )
}

export default AboutMe
