import { Typography } from "@mui/material";
import PageWrapper from "../../components/page-wrapper";

function App() {
  return (
    <PageWrapper>
      <Typography>home</Typography>
    </PageWrapper>
  );
}

export default App;
