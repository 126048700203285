import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { ReactElement } from "react";
import Menu from "../menu";

const PageWrapper = (props: { children: ReactElement }) => {
  const { children } = props;

  return (
    <Container>
      <Box sx={{ marginTop: "64px" }}>
        <Grid container>
          <Menu />
          {children}
        </Grid>
      </Box>
    </Container>
  );
};

export default PageWrapper;
