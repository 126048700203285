import { Grid, Typography } from "@mui/material";
import PageWrapper from "../../components/page-wrapper";

const Contact = () => {
  return (
    <PageWrapper>
      <>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Typography>This is the contact me page</Typography>
        </Grid>
      </>
    </PageWrapper>
  );
};

export default Contact;
