import { Box, Grid } from "@mui/material";
import { nanoid } from "nanoid";

const ProjectStep = (props: { stepName: string }) => {
  const { stepName } = props;
  return (
    <Grid item xs={12} md={3}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ fontSize: "1.5rem", textAlign: "center" }}>{stepName}</Box>
      </Box>

      <Box className="picture-box">Im a picture</Box>
    </Grid>
  );
};

const myProjects = [
  {
    projectName: "SelfWealth Rewrite",
    steps: [
      { stepName: "original SW site", screenShot: "sw-original.png" },
      { stepName: "Figma Design", screenShot: "sw-figma.png" },
      { stepName: "New SW site", screenShot: "sw-new.png" },
    ],
  },
  {
    projectName: "Woodlawn Rewrite",
    steps: [
      { stepName: "original Woodlawn site", screenShot: "sw-original.png" },
      { stepName: "Figma Design", screenShot: "sw-figma.png" },
      { stepName: "New Woodlawn site", screenShot: "sw-new.png" },
    ],
  },
];

const ProjectBar = () => {
  return (
    <Grid container>
      {myProjects.map((theProject) => {
        return (
          <Grid
            container
            item
            xs={12}
            sx={{ paddingBottom: "1rem" }}
            key={nanoid()}
          >
            <Grid item xs={12} md={3}>
              <Box sx={{ fontSize: "1.5rem", textAlign: "center" }}>
                {theProject.projectName}
              </Box>
            </Grid>
            {theProject.steps.map((step) => {
              return <ProjectStep stepName={step.stepName} key={nanoid()} />;
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProjectBar;
