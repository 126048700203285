import { Typography } from "@mui/material";
import PageWrapper from "../../components/page-wrapper";

const NotFound = () => {
  return (
    <PageWrapper>
      <Typography>Page 404: That page is not in my site</Typography>
    </PageWrapper>
  );
};
export default NotFound;
