import PageWrapper from "../../components/page-wrapper";
import ProjectBar from "../../components/project-bar";

const AboutMe = () => {
  return (
    <PageWrapper>
      <>
        <ProjectBar />
      </>
    </PageWrapper>
  );
};

export default AboutMe;
